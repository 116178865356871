* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-s{
    position: fixed;
    padding: 0px;
    margin: 0px;
    /* width: fit-content;
    height: fit-content;;
    display: grid;
    grid-template-columns: 1fr 15fr; */
    display: flex;
    height: -webkit-fill-available;
}

.right-s{
    /* position: initial; */
    /* display: grid;
    grid-template-rows: 2fr 4fr; */
    /* background-color: blue; */
    width: -webkit-fill-available;
    padding: 0px;
    margin: 0px;
    margin-top: 15px;
    float: right;
    overflow-y:auto

}

.rigth-s group-s label{
    display: inline-block;
    text-align: right;

}
.search-s{
    position: relative;
    width: 100%;
    /* display: flex; */
    float: left;
    /* height: 0px;
    width: 100%;
    padding-top: 53px;
    padding-bottom: 68px;
    flex-direction: row;
    margin-top: -620px;
    margin-left: 0px; */
    /* background-color: blue; */
    /* background-color: blue; */

}
.display-s{
    width: 100%;
    height:100%;
    position: relative;
    /* float: left;
    clear: left; */
    display: flex;
    flex-direction: column;
    /* overflow-y: scroll; */
}
.search-s input{
   width: 14vw;
    height: 2vw;
    font-size: 17px;
    margin-right: 30px;
    margin-left: -560px;
    border: 2px solid #eee;
    padding: 10px;
    border-radius: 10px;

}
.search-s button{
    width: 8vw;
    height: 2vw;
    font-size: 1vw;
    padding-left: 10px;
    margin-right: 1vw;
    border: 2px solid #eee;
    padding: 5px;
    border-radius: 10px;
    margin-left: 5px;
}
.search-s button:hover{
    cursor: pointer;
}


.left-s button:hover{
    cursor: pointer;
}
.table-s output{
    height: 20px;
    width: 35px;
}

.table-s{
    display: revert;
    border: 0px;
    display: flex;
    /* overflow-y: scroll; */
    border: 1px solid black;
    /* background-color: cyan; */
    width: -webkit-fill-available;
    /* border-collapse: collapse; */
    border-color: white;
    height: 600px;
    margin: 0px;
    flex-direction: row;
    padding: 15px;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
.table-s tr{
    /* border: 1px solid black; */
    margin: 15px;
    padding: 25px;
} 
.table-s td{
    padding: 20px;
}
.output-s{
    width: 200px;
    height: 5px;
    font-size: 17px;
    margin: 0px;
    padding: 0px;
    /* border: 1px solid black; */
    padding-left: 10px;
    border-radius: 20px;
    background: azure;
}
