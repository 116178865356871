* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-f{
    /* position: relative; */
    padding: 0px;
    margin: 0px;
    height: -webkit-fill-available;
    display: flex;
    /* width: fit-content;
    height: fit-content;
    display: grid;
    grid-template-columns: 2fr 15fr; */
}


.right-f{
    /* position: initial; */
    /* display: grid;
    grid-template-rows: 2fr 4fr; */
    /* background-color: blue; */
    /* position: relative; */
    /* width: 100%;
    padding: 0px;
    margin: 0px;
    overflow-y:hidden;
    float: left; */
    width: -webkit-fill-available;
    padding: 20px;
    padding-left: 0px;
    margin: 0px;
    height: auto;
    overflow-y: hidden;
    float: right;
}


.rigth-f group-f label{
    display: inline-block;
    text-align: right;

}
.search-f{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 250px;


}
/* .search-fdiv{
    background-color: red;
} */
.display-f{
    width: 100%;
    height:100%;
    position: relative;
    /* float: left;
    clear: left; */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.search-f input{
    width: 14vw;
    height: 2vw;
    font-size: 15px;
    margin-right: 30px;
    margin-left: -560px;
    border: 2px solid #eee;
    padding: 10px;
    border-radius: 10px;

}
.search-f button{
    width: 8vw;
    height: 2vw;
    font-size: 1vw;
    padding-left: 10px;
    margin-right: 1vw;
    border: 2px solid #eee;
    padding: 0vw;
    border-radius: 10px;
    margin-left: 0vw;
}
.search-f button:hover{
    cursor: pointer;
}

.result{
    /* background-color: red; */
    width: 100%;
    height: 100px;
  }