.left-h{
    /* position: relative;
    padding: 20px;
    background-color:black;
    display: flex;
    flex-direction: column;
    height: 1000px;
    width: 150px;
    float: left; */
    position: relative;
    padding: 0px;
    background-color: black;
    display: flex;
    flex-direction: column;
    height: 1082px;
    width: 128px;
    float: left;
}
.left-h button{
    width: 120px;
    height: 37px;
    font-size: 15px;
    padding: 0px;
    background: white;
    color: black;
    border: 1px solid #eee;
    border-radius: 15px;
    text-shadow: none;
    margin: 0px;
    margin-left: -18px;
}
.left a{
    background-color: black;
}
.left-h a button{
margin: 0px;
}
.left-h button:hover{
    cursor: pointer;
}
.class1 {
    margin: 3px;
    margin-top: -2px;
    margin-left: -16px;
    padding: 0px;
    width: 100%;
    background-color: black;
    z-index: 1;

}
.class1 button{
    width: 120px;
    height: 25px;
    font-size: 15px;
    padding-left: 7px;
    background: white;
    color: black;
    border: 1px solid #eee;
    border-radius: 20px;
    text-shadow: none;
    margin: 10px;
}
.dropbtn {
    background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .dropdown {
    /* position: relative;
    display: inline-block;
    padding: 10px; */
    position: relative;
    display: inline-block;
    padding: 18px;
    width: 128px;
  }
  
  .dropdown-content {
    /* display: none;
    position: absolute;
    background-color: black;
    width: 126px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -15px; */
    display: none;
    position: absolute;
    background-color: black;
    width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -17px;
    margin-top: 10px;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 15px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content button{
    align-self: center;
  }
  
  .dropdown-content button:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: #3e8e41;}