* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-s{
    position: fixed;
    padding: 0px;
    margin: 0px;
    width: fit-content;
        height: -webkit-fill-available;
    display: grid;
    grid-template-columns: 2fr 20fr;
    /* overflow: scroll; */

}

.right-ud{
    /* position: initial; */
    /* display: grid;
    grid-template-rows: 2fr 4fr; */
    /* background-color: blue; */
    width: -webkit-fill-available;
    /* height: 125%; */
    padding: 0px;
    margin: 0px;
    margin-top: 15px;
    /* overflow: scroll; */
    /* overflow-y:auto */
    overflow-y: scroll;

}

.rigth-ud group-s label{
    display: inline-block;
    text-align: right;

}
.search-s{
    position: relative;
    width: 100%;
    /* display: flex; */
    float: left;
    /* height: 0px;
    width: 100%;
    padding-top: 53px;
    padding-bottom: 68px;
    flex-direction: row;
    margin-top: -620px;
    margin-left: 0px; */
    /* background-color: blue; */
    /* background-color: blue; */

}
.display-s{
    width: 100%;
    height:100%;
    position: relative;
    /* float: left;
    clear: left; */
    display: flex;
    flex-direction: column;
    
    /* overflow-y: scroll; */
}
.search-s input{
    width: 14vw;
    height: 2vw;
    font-size: 17px;
    margin-right: 30px;
    margin-left: -560px;
    border: 2px solid #eee;
    padding: 10px;
    border-radius: 10px;

}
.search-s button{
    width: 8vw;
    height: 2vw;
    font-size: 1vw;
    padding-left: 10px;
    margin-right: 1vw;
    border: 2px solid #eee;
    padding: 5px;
    border-radius: 10px;
    margin-left: 5px;
}
.search-s button:hover{
    cursor: pointer;
}
.class2{
    background-color: white;
    width: 50px;
}

.result{
    /* background-color: red; */
    width: 100%;
    height: 100px;
    
  }
  table{
    border: 1px solid black;
    margin: 10px;
    
  }
.tr2{
    border: 1px solid black;
  }
/* td{
    border: 1px solid black;
  } */
.edit{
    margin: 0px;
    width: 50px;
    padding-left: 15px;
  }