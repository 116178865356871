* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #fff;
}
.wrap-login100 {
    width: 390px;
    background: #fff;
}
.p-b-20 {
    padding-bottom: 20px;
}

.p-t-85 {
    padding-top: 25px;
}
.p-b-70 {
    padding-bottom: 20px;
}
.login100-form-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 39px;
    color: #333;
    line-height: 1.2;
    text-align: center;
}
/* .login100-form-avatar {
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}
img {
    vertical-align: middle;
    border-style: none;
} */
.validate-input {
    position: relative;
}
.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #d9d9d9;
}
.m-b-35 {
    margin-bottom: 30px;
}

.input100 {
    font-family: Poppins-SemiBold;
    font-size: 18px;
    color: #555;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 52px;
    background: 0 0;
    padding: 0 5px;
}
input {
    outline: none;
    border: none;
}

.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}
.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    background: #57b846;
}
.focus-input100::after {
    font-family: Poppins-Medium;
    font-size: 18px;
    color: #999;
    line-height: 1.2;
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 15px;
    left: 0;
    padding-left: 5px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}
.m-b-50 {
    margin-bottom: 50px;
}
.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.login100-form-btn {
    font-family: Poppins-Medium;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    background-color: #57b846;
    border-radius: 25px;
    box-shadow: 0 10px 30px 0 rgba(87,184,70,.5);
    -moz-box-shadow: 0 10px 30px 0 rgba(87,184,70,.5);
    -webkit-box-shadow: 0 10px 30px 0 rgba(87,184,70,.5);
    -o-box-shadow: 0 10px 30px 0 rgba(87,184,70,.5);
    -ms-box-shadow: 0 10px 30px 0 rgba(87,184,70,.5);
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}