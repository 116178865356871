* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-u{

    padding: 0px;
    margin: 0px;
display: flex;
}

.right-u{
    /* position: initial; */
    /* display: grid;
    grid-template-rows: 2fr 4fr; */
    /* background-color: blue; */
    width: -webkit-fill-available;
    padding: 0px;
    margin: 0px;
    overflow-y:auto

}

.rigth-u group-s label{
    display: inline-block;
    text-align: right;

}
.search-u{
    position: relative;
    width: 100%;
    /* display: flex; */
    float: left;
    /* height: 0px;
    width: 100%;
    padding-top: 53px;
    padding-bottom: 68px;
    flex-direction: row;
    margin-top: -620px;
    margin-left: 0px; */
    /* background-color: blue; */
    /* background-color: blue; */

}
.display-u{
    width: 100%;
    height:100%;
    position: relative;
    /* float: left;
    clear: left; */
    display: flex;
    flex-direction: column;
    /* overflow-y: scroll; */
}
.search-u input{
    width: 200px;
    height: 30px;
    font-size: 17px;
    margin-right: 30px;
    margin-left: -560px;
    border: 2px solid #eee;
    padding: 10px;
    border-radius: 10px;

}
.search-u button{
    width: 200px;
    height: 30px;
    font-size: 17px;
    padding-left: 10px;
    margin-right: 10px;
    border: 2px solid #eee;
    padding: 5px;
    border-radius: 10px;
    margin-left: 5px;
}
.search-u button:hover{
    cursor: pointer;
}

.table-u output{
    height: 20px;
    width: 35px;
}

.table-u{
    display: revert;
    border: 0px;
    display: flex;
    width:-webkit-fill-available;
    border-collapse: collapse;
    border-color: white;
    height: 600px;
    margin: 0px;
    flex-direction: column;
    padding: 20px;
    /* flex-wrap: wrap; 
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start; */
    /* overflow-y: scroll; */

}
.table-u button{
    color: white;
    width: 150px;
    height: 30px;
    font-size: 20px;
    padding-left: 7px;
    background: white;
    color: black;
    border: 1px solid black;
    border-radius: 20px;
    text-shadow: none;
    margin: 10px;
}
.table-u tr{
    /* border: 1px solid black; */
    margin: 20px;
    padding-right: 10px;
} 
.table-u td{
    padding: 20px;
     width: 40px;
}
.output-u{
    width: 200px;
    height: 5px;
    font-size: 17px;
    margin: 0px;
    padding: 0px;
    /* border: 1px solid black; */
    padding-left: 10px;
    border-radius: 20px;
    background: azure;
}
