* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-h{
    position: fixed;
    padding: 0px;
    margin: 0px;
    /* width: fit-content;
    height: fit-content;;
    display: grid;
    grid-template-columns: 2fr 15fr; */

}
.left-h{
    position: initial;
    padding: 20px;
    background-color:black;
    display: flex;
    flex-direction: column;
    height: 1000px;
    width: 186px;
}


.right-h{
    height: 1000px;
    overflow:hidden ;
 
}


th {
	/* color: #FFF; */
	font-size: 24px;
	font-weight: bold;
	text-align: left;
    border: 0px;
}

.table-h td input
{
    width: 250px;
    height: 30px;
    font-size: 17px;
    padding-left: 10px;
    background-color: #f5f5ed;
    margin: 15px;
    border: 2px solid #eee;
    padding: 10px;
    border-radius: 10px;
}
.table-h td input[name~="gender"]{
    width: 25px;
}
.table-h td input[name~="gender"] strong{
    padding-top: 0px;
}
.table-h td input[name~="siblings"]{
    width: 25px;
}
.table-h td input[name~="siblings"] strong{
    padding-top: 0px;
}
strong{
    padding: 0px;
}

/* .table-h
{
    display: revert;
    border: 0px;
    display: flex;
    width: -webkit-fill-available;
    border-collapse: collapse;
    border-color: white;
    height: 600px;
    margin: 0px;
    flex-direction: row;
    padding: 40px;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;

} */
.table-h
{
    display: revert;
    border: 0px;
    display: flex;
    width:-webkit-fill-available;
    border-collapse: collapse;
    border-color: white;
    height: 600px;
    margin: 0px;
    flex-direction: column;
    padding: 20px;
    /* flex-wrap: wrap; 
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start; */
    /* overflow-y: scroll; */


}
.table-h tr{
    margin: 20px;
    padding-right: 10px;
}
.table-h td{
    width: 40px;
}

.reset{
    color: white;
    width: 150px;
    height: 30px;
    font-size: 20px;
    padding-left: 7px;
    background: white;
    color: black;
    border: 1px solid black;
    border-radius: 20px;
    text-shadow: none;
    margin: 10px;
}
.submit{
    color: white;
    width: 150px;
    height: 30px;
    font-size: 20px;
    padding-left: 7px;
    background: white;
    color: black;
    border: 1px solid black;
    border-radius: 20px;
    text-shadow: none;
    margin: 10px;
}
.reset:hover{
    cursor: pointer;
}
.submit:hover{
    cursor: pointer;
}
