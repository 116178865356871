.header{
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #131921;
    position: sticky;
    top: 0;
    z-index: 100;
}
.navbar{
    width: 100%;
  
}
.navbar button{
    float: right;
    width: 55px;
    height: 30px;
    margin-right: 20px;
    border-radius: 5px;
}